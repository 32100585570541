@import "../../base/variables-colors";
@import "../../base/variables-typography";
@import "../../base/variables-breakpoints";
@import "../../base/variables";
@import "../../base/mixins";


.Gallery {
    &-Inner {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        max-width: $width-md;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1rem;
        text-align: center;
        &--xxl {
            max-width: 9000px;
            max-width: 100vw;
        }
        &--xl {
            max-width: $width-xl;
        }
        &--lg {
            max-width: $width-lg;
        }
        &--md {
            // note this is the default
        }
        &--sm {
            max-width: $width-sm;
        }
        &--xs {
            max-width: $width-xs;
        }
    }
    &-Item {
        position: relative;
        display: block;
        flex: 0 0 25%;
        padding-top: 25%;
        @media screen AND (max-width:$less-than-sm) {
            flex: 0 0 50%;
            padding-top: 50%;
            &:nth-child(3) {
                order: 5;
            }
            &:nth-child(4) {
                order: 2;
            }
            &:nth-child(5) {
                order: 3;
            }
            &:nth-child(6) {
                order: 4;
            }
            &:nth-child(7) {
                order: 6;
            }
            &:nth-child(2),
            &:nth-child(8) {
                display: none;
            }
        }
    }
    &-Thumb {
        display: block;
        position: absolute;
        top: 2%;
        left: 2%;
        width: 96%;
        height: 96%;
        object-fit: cover;
        background: $color-bg-lo;
        overflow: hidden;
        opacity: 0;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
        transform-origin: 150% 100%;
        transition: all 800ms !important;
        .Gallery-Item:nth-child(odd) & {
            transform-origin: -50% 100%;
            transform: rotate(-5deg) translate3d(0,-10px,0);
        }
        .Gallery-Item:nth-child(even) & {
            transform: rotate(5deg) translate3d(0,-10px,0);
            transition-delay: 200ms !important;
        }
        &.lazyloaded {
            transform: rotate(0deg) translate3d(0,0,0) !important;
        }
        &-Fallback {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}